/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */

/* eslint-disable no-param-reassign */
import { types } from 'mobx-state-tree';
import { getUserRoles } from '../services/getUserRoles';

export const UserRole = types.model('UserRole', {
  roleId: types.identifier,
  label: types.string,
  owner: types.maybeNull(types.string),
  createdBy: types.maybeNull(types.string),
  isELLRole: types.maybeNull(types.boolean)
});

export default types
  .model('UserRoles', {
    userRoles: types.map(UserRole),
    favouriteRoles: types.array(types.string),
    formattedFavouriteRoles: types.map(UserRole),
    customRoles: types.map(UserRole),
    standardRoles: types.array(UserRole),
    ellStandardRoles: types.array(UserRole)
  })
  .views(self => ({
    getRoleById(roleId) {
      return self.userRoles.get(roleId) || '';
    }
  }))
  .actions(self => ({
    async getUserRoles() {
      const {
        standardRoles = [],
        customRoles = [],
        ellStandardRoles = [],
        favouriteRoles = []
      } = await getUserRoles();
      self.setUserRoles([
        ...standardRoles,
        ...customRoles,
        ...ellStandardRoles
      ]);
      self.setStandardRoles(standardRoles, ellStandardRoles);
      self.setCustomRoles(customRoles);
      self.setFavoriteRoles(favouriteRoles);
    },

    setStandardRoles(standardRoles, ellStandardRoles) {
      self.standardRoles = standardRoles;
      self.ellStandardRoles = ellStandardRoles;
    },

    setCustomRoles(roles) {
      roles.forEach(role => {
        self.customRoles.put({
          roleId: role.roleId,
          label: role.label,
          owner: role.userOwner,
          createdBy: role.createdBy
        });
      });
    },

    setUserRoles(roles) {
      roles.forEach(role => {
        self.userRoles.put({
          roleId: role.roleId,
          label: role.label,
          owner: role.userOwner,
          createdBy: role.createdBy
        });
      });
    },

    addUserRole(roleId, label, owner, createdBy) {
      self.userRoles.put({ roleId, label, owner, createdBy });
      /** Maintaining separate custom roles in order to remove duplicates in userRoleDropDown
       * So adding new userRole to custom roles also
       */
      self.customRoles.put({ roleId, label, owner, createdBy });
    },

    getRoleOwnerId(roleId) {
      const role = self.userRoles.get(roleId);
      return role ? role.createdBy : null;
    },

    setFavoriteRoles(favRoles) {
      self.favouriteRoles = favRoles;
      /** Favourite roles from API contains only roleId so getting other data
       * and making favourite roles in correct format */
      self.getformattedFavouriteRoles();
      /** To remove duplicate roleIds removing favourite roles items from custom roles */
      self.removeFavouritesFromCustom(favRoles);
    },

    removeFavouritesFromCustom(roles) {
      roles.forEach(roleId => {
        self.customRoles.delete(roleId);
      });
    },

    isRoleUserFavorite(roleId) {
      return self.favouriteRoles.find(favoriteRole => favoriteRole === roleId);
    },

    addUserFavoriteRole(id) {
      self.favouriteRoles.push(id);
      /** Once a custom role is added to favourite role, removing the same from custom roles */
      self.customRoles.delete(id);
      /** With roleId received, getting other data for favourite roles */
      self.userRoles.forEach((data, roleId) => {
        if (roleId === id) {
          self.formattedFavouriteRoles.put({
            roleId,
            label: data.label,
            owner: data.owner,
            createdBy: data.createdBy
          });
        }
      });
    },

    removeUserFavoriteRole(id) {
      const index = self.favouriteRoles.findIndex(
        favoriteRole => favoriteRole === id
      );
      if (index !== -1) {
        self.favouriteRoles.splice(index, 1);
        /** Removing favourite role from formatted favourite roles also */
        self.formattedFavouriteRoles.delete(id);
        /** Once a favourite role is removed from favourite roles, adding the same in custom roles */
        self.userRoles.forEach((data, roleId) => {
          if (roleId === id) {
            self.customRoles.put({
              roleId,
              label: data.label,
              owner: data.owner,
              createdBy: data.createdBy
            });
          }
        });
      }
    },
    // Formatting favourite roles
    getformattedFavouriteRoles() {
      self.favouriteRoles.forEach(favoriteRole => {
        self.userRoles.forEach(userRole => {
          if (userRole.roleId === favoriteRole) {
            self.formattedFavouriteRoles.put({
              roleId: userRole.roleId,
              label: userRole.label,
              owner: userRole.owner,
              createdBy: userRole.createdBy
            });
          }
        });
      });
    }
  }));
